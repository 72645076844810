import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

if (environment.sentry.enabled) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    tracePropagationTargets: environment.sentry.tracePropagationTargets,
    // Performance Monitoring
    tracesSampleRate: environment.sentry.tracesSampleRate, // Set to 1.0 to capture 100%
    // Session Replay
    replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
