import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.service';
import { BookProjectMetricsDto } from '../models/book-project.model';

@Injectable({
  providedIn: 'root'
})
export class BookProjectMetricsService {
  private baseUrl = 'book-project-metrics';

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {}

  private createCompleteRoute(route: string): string {
    return `${this.envUrl.apiUrl}/${this.baseUrl}/${route}`;
  }

  trackView(bookProjectId: string): Observable<void> {
    return this.http.post<void>(this.createCompleteRoute(`${bookProjectId}/view`), {});
  }

  toggleLike(bookProjectId: string): Observable<BookProjectMetricsDto> {
    return this.http.post<BookProjectMetricsDto>(this.createCompleteRoute(`${bookProjectId}/like`), {});
  }

  getMetrics(bookProjectId: string): Observable<BookProjectMetricsDto> {
    return this.http.get<BookProjectMetricsDto>(this.createCompleteRoute(`${bookProjectId}`));
  }
}
