import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

export interface ChatSettings {
  useEntireBookContext: boolean;
  model: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private readonly SETTINGS_STORAGE_KEY = 'chatSettings';
  private readonly HISTORY_STORAGE_KEY = 'chatHistory';

  private chatHistorySubject = new BehaviorSubject<ChatMessage[]>([]);
  private settingsSubject: BehaviorSubject<ChatSettings>;
  private showChatSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    const storedSettings = this.loadSettingsFromStorage();
    this.settingsSubject = new BehaviorSubject<ChatSettings>(storedSettings);

    const storedHistory = this.loadHistoryFromStorage();
    this.chatHistorySubject.next(storedHistory);
  }

  // Chat history methods
  get chatHistory$(): Observable<ChatMessage[]> {
    return this.chatHistorySubject.asObservable();
  }

  addMessage(message: ChatMessage): void {
    const currentHistory = this.chatHistorySubject.value;
    const updatedHistory = [...currentHistory, message];
    this.chatHistorySubject.next(updatedHistory);
    this.saveHistoryToStorage(updatedHistory);
  }

  clearHistory(): void {
    this.chatHistorySubject.next([]);
    this.saveHistoryToStorage([]);
  }

  // Chat settings methods
  get settings$(): Observable<ChatSettings> {
    return this.settingsSubject.asObservable();
  }

  updateSettings(newSettings: Partial<ChatSettings>): void {
    const currentSettings = this.settingsSubject.value;
    const updatedSettings = { ...currentSettings, ...newSettings };
    this.settingsSubject.next(updatedSettings);
    this.saveSettingsToStorage(updatedSettings);
  }

  setModel(model: string): void {
    this.updateSettings({ model });
  }

  get isHighQuality(): boolean {
    return this.settingsSubject.value.model === this.getHighQualityModel;
  }

  get getHighQualityModel(): string {
    return 'anthropic/claude-3.7-sonnet:beta';
  }

  get getLowQualityModel(): string {
    return 'google/gemini-2.0-flash-001';
  }

  get showChat$(): Observable<boolean> {
    return this.showChatSubject.asObservable();
  }

  toggleChat(): void {
    this.showChatSubject.next(!this.showChatSubject.value);
  }

  // Private helper methods
  private loadSettingsFromStorage(): ChatSettings {
    const storedSettings = localStorage.getItem(this.SETTINGS_STORAGE_KEY);
    if (storedSettings) {
      return JSON.parse(storedSettings);
    }
    return this.getDefaultSettings();
  }

  private saveSettingsToStorage(settings: ChatSettings): void {
    localStorage.setItem(this.SETTINGS_STORAGE_KEY, JSON.stringify(settings));
  }

  private loadHistoryFromStorage(): ChatMessage[] {
    const storedHistory = localStorage.getItem(this.HISTORY_STORAGE_KEY);
    if (storedHistory) {
      return JSON.parse(storedHistory);
    }
    return [];
  }

  private saveHistoryToStorage(history: ChatMessage[]): void {
    localStorage.setItem(this.HISTORY_STORAGE_KEY, JSON.stringify(history));
  }

  private getDefaultSettings(): ChatSettings {
    return {
      useEntireBookContext: true,
      model: this.getLowQualityModel
    };
  }

}
