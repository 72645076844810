// book-project-pdf.service.ts
import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { firstValueFrom } from 'rxjs';

import { BookProjectApiService } from './book-project-api.service';

// ---------- DTO Imports ----------
import { BookProjectDto } from '../models/book-project.model';
import { SharedBookProjectDto, SharedChapterDto } from '../models/book-project.model';
import { ChapterDto } from '../models/chapter.model';

// Setup pdfMake with its default Roboto fonts
pdfMake.vfs = pdfFonts.default.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class BookProjectPdfService {
  constructor(private bookProjectService: BookProjectApiService) {}

  /**
   * Main function that decides which API to call based on ownership,
   * then generates the PDF for either the full BookProjectDto
   * or the shared (public) SharedBookProjectDto.
   */
  public async generatePdf(bookId: string): Promise<void> {
    let bookProject: BookProjectDto;

    const sharedProject = await firstValueFrom(this.bookProjectService.getSharedBookProject(bookId));
    bookProject = this.convertSharedToBookProject(sharedProject);

    // Build and download the PDF
    const docDefinition = this.buildDocDefinition(bookProject);
    pdfMake.createPdf(docDefinition).download(`${bookProject.title}.pdf`);
  }

  /**
   * Converts a SharedBookProjectDto (public data) to a minimal
   * BookProjectDto so we can reuse the same PDF-building logic.
   */
  private convertSharedToBookProject(shared: SharedBookProjectDto): BookProjectDto {
    // Convert each SharedChapterDto into a form compatible with ChapterDto
    const convertedChapters: ChapterDto[] = shared.chapters.map(
      (sharedChapter: SharedChapterDto) => ({
        // Some fields in ChapterDto may not exist in SharedChapterDto,
        // so assign defaults or empty strings where needed
        id: '',
        bookProjectId: shared.id,
        title: sharedChapter.title,
        sequenceNumber: sharedChapter.sequenceNumber,
        fullText: sharedChapter.fullText,
        summary: '',
        instructions: '',
        beats: []
      })
    );

    // Return a BookProjectDto-like object
    return {
      id: shared.id,
      title: shared.title,
      userId: shared.applicationUserId || '',
      description: shared.description || '',
      isPublic: true,                   // A shared book is presumably public
      writingStyle: null,               // Not available in SharedBookProjectDto
      storyInfo: null,                  // Not available in SharedBookProjectDto
      characters: null,                 // Not available in SharedBookProjectDto
      structure: null,                  // Not available in SharedBookProjectDto
      chapters: convertedChapters,
      researchNotes: null,             // Not available in SharedBookProjectDto
      createdAt: '',                   // Not provided
      updatedAt: '',                   // Not provided
    };
  }

  /**
   * Builds the docDefinition object for pdfMake, setting up:
   *  - margins
   *  - fonts
   *  - title page
   *  - chapters
   */
  private buildDocDefinition(bookProject: BookProjectDto) {
    return {
      pageSize: 'A4',
      pageMargins: [90, 60, 90, 60], // Larger side margins => fewer words per line

      content: [
        // -- Title Page --
        {
          text: bookProject.title,
          style: 'titlePage',
        },
        { text: '', pageBreak: 'after' },

        // -- Chapters --
        ...this.buildChapterContent(bookProject),
      ],

      styles: {
        titlePage: {
          fontSize: 24,
          bold: true,
          margin: [0, 100, 0, 20],
          alignment: 'center',
        },
        chapterTitle: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 20],
          alignment: 'center',
        },
        bodyText: {
          fontSize: 13,
          lineHeight: 1.6,
          alignment: 'justify',
          margin: [0, 0, 0, 10],
        },
      },

      defaultStyle: {
        // uses default Roboto font
      },
    };
  }

  /**
   * Splits the chapters into an array of pdfMake paragraph objects,
   * ensuring each chapter starts on a new page.
   */
  private buildChapterContent(bookProject: BookProjectDto): any[] {
    if (!bookProject.chapters) {
      return [];
    }

    const output: any[] = [];
    bookProject.chapters.forEach((chapter, index) => {
      // Skip null or undefined chapters
      if (!chapter) {
        return;
      }

      // Page break before every chapter except the first
      if (index !== 0) {
        output.push({ text: '', pageBreak: 'before' });
      }

      // Chapter title
      output.push({
        text: chapter.title,
        style: 'chapterTitle',
      });

      // Split the chapter text by new lines for paragraphs
      const paragraphs = chapter.fullText
        ? chapter.fullText.split('\n').filter(p => p.trim() !== '')
        : [];

      paragraphs.forEach(paragraph => {
        output.push({
          text: paragraph.trim(),
          style: 'bodyText',
        });
      });
    });
    return output;
  }
}
