import { Component, OnInit, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForOf, NgIf } from "@angular/common";
import { SharedBookProjectDto } from "../../../core/models/book-project.model";
import { Meta, Title } from "@angular/platform-browser";
import { EnvironmentUrlService } from "../../../core/services/environment-url.service";
import {firstValueFrom, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookProjectMetricsService } from "../../../core/services/book-project-metrics.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../core/services/user.service";
import { BookProjectPdfService } from "../../../core/services/book-project-pdf.service";
import { BookProjectEpubService } from "../../../core/services/book-project-epub.service";
import { saveAs } from 'file-saver';

declare var google: any;

@Component({
  selector: 'app-book-project-viewer',
  standalone: true,
  templateUrl: './book-project-viewer.component.html',
  imports: [
    NgIf,
    NgForOf
  ],
  styleUrls: ['./book-project-viewer.component.scss']
})
export class BookProjectViewerComponent implements OnInit, OnDestroy {
  sharedBookProject: SharedBookProjectDto;
  currentChapter: number = 0;
  isScrolling: boolean = false;
  bookCoverUrl: string;
  isMobile: boolean = false;
  isAuthenticated: boolean = false;
  currentUserId: string | null = null;
  shareButtonText: string = 'Copy share link';
  isButtonPressed: boolean = false;
  isDownloadingPdf = false;
  isDownloadingEpub = false;
  downloadWarning: string = '';
  downloadError: string = '';

  private scrollTimeout: any;
  private destroy$ = new Subject<void>();

  constructor(
    private meta: Meta,
    private title: Title,
    private envUrl: EnvironmentUrlService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private metricsService: BookProjectMetricsService,
    private userService: UserService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private bookProjectPdfService: BookProjectPdfService,
    private bookProjectEpubService: BookProjectEpubService
  ) {
    this.checkMobileDevice();
  }

  ngOnInit() {
    this.route.data.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.sharedBookProject = data['bookProject'];
      this.updateMetaTags(this.sharedBookProject);
      this.loadBookCover();
      this.setupScrollListener();
      this.trackView();
    });

    this.userService.authChanged.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isAuth => {
      this.isAuthenticated = isAuth;
      if (isAuth) {
        this.loadCurrentUser();
      } else {
        this.currentUserId = null;
      }
    });

    // Initial auth check
    this.userService.isUserAuthenticated().subscribe(isAuth => {
      this.isAuthenticated = isAuth;
      if (isAuth) {
        this.loadCurrentUser();
      }
    });

    if (!window['googleTranslateElementInit']) {
      this.loadGoogleTranslateScript();
      this.initGoogleTranslate();
    }
  }

  private loadCurrentUser() {
    this.userService.getUser().pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      this.currentUserId = user.id;
    });
  }

  private trackView() {
    if (this.sharedBookProject) {
      this.metricsService.trackView(this.sharedBookProject.id).subscribe();
    }
  }

  async downloadPdf() {
    if (this.isDownloadingPdf || !this.sharedBookProject) return;

    try {
      this.isDownloadingPdf = true;
      this.downloadError = '';
      await this.bookProjectPdfService.generatePdf(this.sharedBookProject.id);
      this.toastr.success('PDF downloaded successfully');
    } catch (error) {
      console.error('PDF download error:', error);
      this.downloadError = 'An error occurred while generating the PDF.';
      this.toastr.error('Failed to download PDF');
    } finally {
      this.isDownloadingPdf = false;
    }
  }

  async downloadEpub() {
    if (this.isDownloadingEpub || !this.sharedBookProject) return;

    try {
      this.isDownloadingEpub = true;
      this.downloadError = '';
      this.downloadWarning = '';

      // First validate the EPUB
      const validation = await firstValueFrom(
        this.bookProjectEpubService.validateEpub(this.sharedBookProject.id)
      );

      // Check for empty chapters warning
      const emptyChaptersIssue = validation.issues.find(issue =>
        issue.toLowerCase().includes('chapters are empty'));

      // Separate other critical issues
      const criticalIssues = validation.issues.filter(issue =>
        !issue.toLowerCase().includes('chapters are empty'));

      if (emptyChaptersIssue) {
        this.downloadWarning = emptyChaptersIssue;
      }

      if (criticalIssues.length > 0) {
        this.downloadError = `Cannot generate EPUB: ${criticalIssues.join(', ')}`;
        return;
      }

      // If valid or only has warnings, proceed with download
      const epubBlob = await firstValueFrom(
        this.bookProjectEpubService.downloadEpub(this.sharedBookProject.id)
      );

      // Use file-saver to download the file
      saveAs(epubBlob, `${this.sharedBookProject.title.replace(/\s+/g, '_')}.epub`);
      this.toastr.success('EPUB downloaded successfully');
    } catch (error) {
      console.error('EPUB download error:', error);
      this.downloadError = 'An error occurred while generating the EPUB file.';
      this.toastr.error('Failed to download EPUB');
    } finally {
      this.isDownloadingEpub = false;
    }
  }

  canLikeBook(): boolean {
    return this.isAuthenticated && this.currentUserId !== this.sharedBookProject?.applicationUserId;
  }

  toggleLike() {
    if (!this.canLikeBook()) {
      if (!this.isAuthenticated) {
        this.toastr.info('Please sign in to like this book');
      }
      return;
    }

    this.metricsService.toggleLike(this.sharedBookProject.id).subscribe({
      next: metrics => {
        this.sharedBookProject.metrics = metrics;
        this.toastr.success(metrics.isLikedByCurrentUser ? 'Book added to your likes' : 'Book removed from your likes');
      },
      error: error => {
        if (error.status === 400) {
          this.toastr.error(error.error);
        } else {
          this.toastr.error('An error occurred while trying to like the book');
        }
      }
    });
  }

  shareBook() {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      this.isButtonPressed = true;
      this.shareButtonText = 'Copied!';

      setTimeout(() => {
        this.isButtonPressed = false;
        this.shareButtonText = 'Copy share link';
        this.cd.detectChanges();
      }, 2000);
    }).catch((err) => {
      console.error('Could not copy text: ', err);
      this.toastr.error('Failed to copy link');
    });
  }

  checkMobileDevice() {
    this.isMobile = /Android|iPhone|iPad|iPod|webOS/i.test(navigator.userAgent);
  }

  openElevenLabsReader() {
    const currentUrl = window.location.href;

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = `elevenlabs-reader://share?url=${encodeURIComponent(currentUrl)}`;
      setTimeout(() => {
        window.location.href = 'https://apps.apple.com/us/app/reader-by-elevenlabs/id6479373050';
      }, 1000);
    } else if (/Android/i.test(navigator.userAgent)) {
      window.location.href = `elevenlabs-reader://share?url=${encodeURIComponent(currentUrl)}`;
      setTimeout(() => {
        window.location.href = 'https://play.google.com/store/apps/details?id=io.elevenlabs.readerapp';
      }, 1000);
    } else {
      this.copyToClipboard(currentUrl);
    }
  }

  private copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      alert('URL copied to clipboard! You can paste it in the Elevenlabs Reader app.');
    }).catch(err => {
      console.error('Failed to copy URL:', err);
    });
  }

  loadBookCover() {
    this.bookCoverUrl = this.envUrl.apiUrl + `/book-project/${this.sharedBookProject.id}/cover`;
  }

  updateMetaTags(project: SharedBookProjectDto) {
    this.title.setTitle(`${project.title} - Talespinner.io | AI-Powered Storytelling Platform`);

    this.meta.updateTag({ name: 'description', content: project.description });
    this.meta.updateTag({ name: 'keywords', content: `Talespinner shared book project, community stories, AI-powered storytelling, creative writing platform, ${project.title}, ${project.genre}` });

    const coverUrl = this.envUrl.apiUrl + `/book-project/${project.id}/cover`;

    // Facebook meta tags
    this.meta.updateTag({ property: 'og:title', content: project.title });
    this.meta.updateTag({ property: 'og:description', content: project.description.length > 200 ? project.description.substring(0, 197) + '...' : project.description });
    this.meta.updateTag({ property: 'og:image', content: coverUrl });
    this.meta.updateTag({ property: 'og:image:secure_url', content: coverUrl });
    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({ property: 'og:site_name', content: 'Talespinner.io' });
    this.meta.updateTag({ property: 'og:type', content: 'article' });

    // Twitter meta tags
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: project.title });
    this.meta.updateTag({ name: 'twitter:description', content: project.description.length > 200 ? project.description.substring(0, 197) + '...' : project.description });
    this.meta.updateTag({ name: 'twitter:image', content: coverUrl });
    this.meta.updateTag({ name: 'twitter:site', content: '@TalespinnerIO' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@TalespinnerIO' });

    // Add deep linking meta tags
    this.meta.updateTag({ property: 'al:ios:url', content: `elevenlabs-reader://share?url=${encodeURIComponent(window.location.href)}` });
    this.meta.updateTag({ property: 'al:ios:app_store_id', content: '6479373050' });
    this.meta.updateTag({ property: 'al:ios:app_name', content: 'Reader by Elevenlabs' });
    this.meta.updateTag({ property: 'al:android:url', content: `elevenlabs-reader://share?url=${encodeURIComponent(window.location.href)}` });
    this.meta.updateTag({ property: 'al:android:package', content: 'io.elevenlabs.readerapp' });
    this.meta.updateTag({ property: 'al:android:app_name', content: 'Reader by Elevenlabs' });
  }

  loadGoogleTranslateScript() {
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);
  }

  initGoogleTranslate() {
    window['googleTranslateElementInit'] = () => {
      new google.translate.TranslateElement(
        { pageLanguage: '', autoDisplay: false },
        'google_translate_element'
      );
    };
  }

  scrollToChapter(event: Event, index: number) {
    event.preventDefault();
    const chapterElement = document.getElementById(`chapter-${index}`);
    if (chapterElement) {
      this.isScrolling = true;
      chapterElement.scrollIntoView({ behavior: 'smooth' });
      this.currentChapter = index;

      setTimeout(() => {
        this.isScrolling = false;
      }, 1000);
    }
  }

  splitIntoParagraphs(text: string): string[] {
    return text.split(/\n{2,}/).map(paragraph => paragraph.trim()).filter(Boolean);
  }

  preserveNewlines(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  setupScrollListener() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', () => {
        if (this.scrollTimeout) {
          clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(() => {
          this.ngZone.run(() => {
            this.isScrolling = false;
            this.updateCurrentChapter();
          });
        }, 100);
      });
    });
  }

  updateCurrentChapter() {
    if (!this.isScrolling && this.sharedBookProject?.chapters) {
      const chapters = this.sharedBookProject.chapters;
      for (let i = chapters.length - 1; i >= 0; i--) {
        const chapterElement = document.getElementById(`chapter-${i}`);
        if (chapterElement && chapterElement.getBoundingClientRect().top <= 100) {
          this.currentChapter = i;
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    window.removeEventListener('scroll', this.updateCurrentChapter);
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }
}
