import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvironmentUrlService} from './environment-url.service';
import {
  BookProjectDto,
  CreateBookProjectDto,
  SharedBookProjectDto,
  UpdateBookProjectDto
} from '../models/book-project.model';
import {PagedResponse} from "../models/paged-response.model";
import {TokenCountResponse} from "../models/token-count.model";

@Injectable({
  providedIn: 'root'
})
export class BookProjectApiService {
  private baseUrl = 'book-project';

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {
  }

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${this.baseUrl}/${route}`;
  }

  getBookProjects(pageNumber: number, pageSize: number ): Observable<PagedResponse<BookProjectDto>> {
    return this.http.get<PagedResponse<BookProjectDto>>(this.createCompleteRoute(`?pageNumber=${pageNumber}&pageSize=${pageSize}`, this.envUrl.apiUrl));
  }

  deleteProject(bookProjectId: string): Observable<any> {
    return this.http.delete(this.createCompleteRoute(`${bookProjectId}`, this.envUrl.apiUrl));
  }

  createBookProject(project: CreateBookProjectDto): Observable<BookProjectDto> {
    return this.http.post<BookProjectDto>(this.createCompleteRoute('', this.envUrl.apiUrl), project);
  }

  getBookProjectById(bookProjectId: string): Observable<BookProjectDto> {
    return this.http.get<BookProjectDto>(this.createCompleteRoute(`${bookProjectId}`, this.envUrl.apiUrl));
  }

  updateBookProject(bookProjectId: string, project: UpdateBookProjectDto): Observable<BookProjectDto> {
    return this.http.put<BookProjectDto>(this.createCompleteRoute(`${bookProjectId}`, this.envUrl.apiUrl), project);
  }

  getSharedBookProject(bookProjectId: string): Observable<SharedBookProjectDto> {
    return this.http.get<SharedBookProjectDto>(this.createCompleteRoute(`shared/${bookProjectId}`, this.envUrl.apiUrl));
  }

  getPublicBooks(pageNumber: number, pageSize: number): Observable<PagedResponse<SharedBookProjectDto>> {
    return this.http.get<PagedResponse<SharedBookProjectDto>>(
      this.createCompleteRoute(`public?pageNumber=${pageNumber}&pageSize=${pageSize}`, this.envUrl.apiUrl)
    );
  }

  getTokenCount(bookProjectId: string): Observable<TokenCountResponse> {
    return this.http.get<TokenCountResponse>(
      this.createCompleteRoute(`${bookProjectId}/tokens`, this.envUrl.apiUrl)
    );
  }
}
